@font-face {
  font-family: Kobe;
  src: url("./fonts/Kobe.otf") format("opentype");
}

html,
body {
  width: 100%;
  height: 100%;

  background: #1ae26a;
  /* overflow: hidden; */
  font-family: "Kobe", sans-serif;
  font-size: 38px;
  line-height: 1.2;
  background-image: url("img/bg.png");
  background-position: center;
  background-size: "cover";
}
.App {
  /* display: flex;
  flex-direction: column; */
  height: 100%;

  padding-bottom: 2em;
}
main {
  /* flex-grow: 100; */
  padding: 2em 1em;
  box-sizing: content-box;
  min-height: 100%;
  /* overflow: auto; */
}
.webcam,
.webcam * {
  user-select: none;
}
.webcam video {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  pointer-events: none;
  mix-blend-mode: multiply;

  /* margin-left: 10vw; */
}

img {
  max-width: 100%;
  mix-blend-mode: multiply;
}

.slide {
  position: relative;
  user-select: none;
}
.inlinelogo {
  display: inline-block;
  height: 0.8em;
  width: auto;
  position: relative;
  top: 0.35em;
}

.button {
  display: block;
  width: auto;
  margin-top: 2em;
  user-select: none;
  font-size: 0.8em;
}

.button span {
  display: inline-block;
  border: 2px solid #000;
  padding: 0.5em 0.5em;
  border-radius: 0.5em;
  line-height: 1;
}

textarea {
  display: block;
  border: 0;
  background: transparent;
  outline: none;
  font-size: 0.6rem;

  /* color: rgb(255, 255, 255); */
  border-bottom: 2px solid #000;
  width: 100%;
  border-radius: 0;
  padding: 1em;
  box-sizing: border-box;
  font-family: "Kobe", sans-serif;
  background: rgba(255, 255, 255, 0.4);
}
input[type="text"]::placeholder,
textarea::placeholder {
  font-size: 1em;
  color: #fff;
}
input[type="text"] {
  box-sizing: border-box;

  display: block;
  border: 0;
  border-bottom: 2px solid #000;
  background: transparent;
  padding: 1em;
  outline: none;
  font-size: 0.6rem;
  font-family: "Kobe", sans-serif;
  /* color: rgb(255, 255, 255); */
  width: calc(100% - 0.4em);
  margin-top: 1em;
  border-radius: 0;
  font-family: "Kobe", sans-serif;
  margin-bottom: 1em;
  background: rgba(255, 255, 255, 0.4);
}

.holdOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayImage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overlayText {
  position: fixed;
  bottom: 2em;
  left: 1em;
  width: calc(100% - 2em);
  font-size: 0.5em;
  color: #fff;
  text-align: center;
}
.overlayText div {
  display: inline-block;
  text-align: left;
}
.overlayCircle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  transform-origin: center;
}

.overlayCircle circle,
.overlayCircle path {
  fill: none;
  stroke: #fff;
  stroke-width: 5;
  pointer-events: none;
  stroke-dasharray: 3;
}
.overlayCircle .activeCircle,
.overlayCircle .activeCircle path {
  stroke: #1ae26a;
  display: none;
  stroke-width: 10;
}

/* range slider */
.rangeSlider {
  position: absolute;
  top: 1em;
  left: 50%;
}
.rangeSlider span {
  position: absolute;
  top: 3em;
  left: 0;
  font-size: 0.4em;
}

.rangeSlider span.low {
}

.rangeSlider span.high {
  left: auto;
  right: 0;
  /* transform: rotate(90deg); */
  /* transform-origin: left bottom; */
}

input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 80vh; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
  transform: rotate(90deg);
  transform-origin: left center;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* border: 1px solid #000000; */
  height: 25px;
  width: 20px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  /* border: 1px solid #000000; */
  height: 25px;
  width: 20px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  /* border: 1px solid #000000; */
  height: 25px;
  width: 20px;
  border-radius: 3px;
  border: none;
  background: #ffffff;
  cursor: pointer;
}

.slider .option {
  font-size: 0.5em;
  text-align: left;
  /* margin-bottom: 0.5em; */
  color: #000;
  display: inline-block;
  margin: 0.1em;
}
.slider .option span {
  display: inline-block;
  padding: 0.5em;
  border: 1px solid #000;
  border-radius: 0.5em;
}

h1.timer {
  font-size: 4rem;
  text-align: center;
}

.sensorsActive {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 1em;
  width: calc(100% - 2em);
  height: 4px;
  background: rgba(255, 255, 255, 0.4);
  background: #000;
  -webkit-animation: sensorsActive 0.7s infinite alternate; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: sensorsActive 0.7s infinite alternate; /* Firefox < 16 */
  -ms-animation: sensorsActive 0.7s infinite alternate; /* Internet Explorer */
  -o-animation: sensorsActive 0.7s infinite alternate; /* Opera < 12.1*/
  animation: sensorsActive 0.7s infinite alternate;
  transform-origin: center;
  z-index: 1000;
}

@keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}
@keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}
@keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}

/* Firefox < 16 */
@-moz-keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}

/* Internet Explorer */
@-ms-keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}

/* Opera < 12.1 */
@-o-keyframes sensorsActive {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}
.camera {
  position: relative;
  margin-top: 1em;
  /* height: calc(100% * 720 / 1280); */
}

.camera video {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  mix-blend-mode: multiply;

  /* width: 100% !important;
  height: 100% !important;
  max-height: 56.25vw !important; */
}
.previewImage {
  position: relative;
  margin-top: 1em;
}

.camera .button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #fff;
  color: #fff;
  border-radius: 0.5em;
  padding: 1em;
  font-size: 0.5em;
}
.previewImage .button {
  position: absolute;
  bottom: 1em;
  right: 0.5em;
  /* background: rgba(255, 255, 255, 0.2); */
  /* border: 2px solid #fff; */
  color: #fff;
  border-radius: 0.5em;
  padding: 0.1em;
  font-size: 0.5em;
  width: 1em;
}

.previewImage .button svg {
  width: 100%;
}
